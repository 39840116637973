import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TripBox } from './styled';

const CustomerInfo = ({
  firstName,
  surname,
  clientType,
  companyAddress,
  companyNumber,
  companyVatNumber,
  email,
}) => {
  return (
    <TripBox>
      <Grid container spacing={2}>
        {firstName && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" display="block">
              <strong>First Name:</strong>
            </Typography>
            <Typography
              variant="body2"
              display="block"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {firstName}
            </Typography>
          </Grid>
        )}
        {surname && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" display="block">
              <strong>Surname:</strong>
            </Typography>
            <Typography
              variant="body2"
              display="block"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {surname}
            </Typography>
          </Grid>
        )}
        {clientType && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" display="block">
              <strong>Client Type:</strong>
            </Typography>
            <Typography
              variant="body2"
              display="block"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {clientType}
            </Typography>
          </Grid>
        )}
        {companyAddress && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" display="block">
              <strong>Company Address:</strong>
            </Typography>
            <Typography
              variant="body2"
              display="block"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {companyAddress}
            </Typography>
          </Grid>
        )}
        {companyNumber && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" display="block">
              <strong>Company Number:</strong>
            </Typography>
            <Typography
              variant="body2"
              display="block"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {companyNumber}
            </Typography>
          </Grid>
        )}
        {companyVatNumber && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" display="block">
              <strong>Company VAT Number:</strong>
            </Typography>
            <Typography
              variant="body2"
              display="block"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {companyVatNumber}
            </Typography>
          </Grid>
        )}
        {email && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" display="block">
              <strong>Email:</strong>
            </Typography>
            <Typography
              variant="body2"
              display="block"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {email}
            </Typography>
          </Grid>
        )}
      </Grid>
    </TripBox>
  );
};

export default CustomerInfo;
