import React from 'react';
import { Box, Grid, Button } from '@mui/material';
import { BackButton, BlockTitle } from './styled';
import { ArrowBack } from '@mui/icons-material';
import { t } from 'i18next';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useNavigate, useParams } from 'react-router-dom';
import Chat from '../chat/Chat'; // Adjust the import path if necessary
import TripStopsSummaryWithData from '../../components/TripSummary/dataWrapper'; // Adjust the import path if necessary

const TripRequestConversation = () => {
  const navigate = useNavigate();
  const { tripRequestCode } = useParams();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BackButton>
            <Button variant="contained" onClick={() => navigate(-1)}>
              <ArrowBack />
              {t('PriceCalc.Button.Back')}
            </Button>
          </BackButton>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <QuestionAnswerIcon sx={{ marginRight: '4px' }} />
            <BlockTitle>{'Conversazione'}</BlockTitle>
          </Box>
          <Chat />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <TripStopsSummaryWithData tripRequestCode={tripRequestCode} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TripRequestConversation;
