import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const BackButton = styled.div`
  button.MuiButton-contained {
    border-radius: 26.5px;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    background: ${theme.colors.darkGrey};
    color: ${theme.colors.black};
    svg {
      margin-right: 5px;
      width: 22px;
      height: 22px;
    }
  }
`;

export const TripBox = styled.div`
  border-radius: 21px;
  border: 1px solid #d9d9d9;
  padding: 30px;
  margin: 15px;
  .verticle_timeline_wrapper {
    .MuiTimelineItem-root {
      .MuiTimelineContent-root {
        position: relative;
        top: -25px;
        @media (max-width: 599px) {
          top: -45px;
        }
      }
      &:first-of-type {
        .MuiTimelineContent-root {
          top: -6px;
          padding-bottom: 26px;
        }
      }
      &:last-child {
        top: -10px;
        .MuiTimelineContent-root {
          top: -6px;
          padding-bottom: 26px;
        }
        @media (max-width: 599px) {
          top: -20px;
          .MuiTimelineContent-root {
            top: -10px;
          }
        }
      }
      @media (max-width: 599px) {
        min-height: 140px;
      }
    }
  }
  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.02em;
    text-align: left;
    span {
      font-size: 20px;
      line-height: 32.45px;
      font-weight: 400;
      color: ${theme.colors.grey3};
    }
  }
  h4 {
    font-size: 20px;
    font-weight: 700;
  }
  p {
    font-size: 20px;
    span {
      font-weight: 700;
    }
    &.Mui-error {
      font-size: 14px;
      line-height: 1;
    }
    @media (max-width: 1500px) {
      font-size: 18px;
    }
  }
  .MuiTimelineItem-root {
    .MuiTimelineDot-root {
      margin: 0;
    }
    &:last-child {
      .MuiTimelineConnector-root {
        display: none;
      }
    }
    h3 {
      color: ${theme.colors.black};
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: -0.02em;
      @media (max-width: 1500px) {
        font-size: 18px;
        line-height: 23px;
      }
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 1.3;
      }
    }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: ${theme.colors.timelineDesc};
      @media (max-width: 599px) {
        line-height: 20px;
      }
    }
  }
  .MuiTimelineSeparator-root {
    .MuiTimelineDot-root {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-weight: 700;
      letter-spacing: -0.02em;
      box-sizing: border-box;
      @media (max-width: 599px) {
        width: 25px;
        height: 25px;
        font-size: 16px;
      }
    }
    .MuiTimelineConnector-root {
      border-color: ${theme.colors.grey1};
    }
  }
  .tripBoxLabel {
    width: 90px;
  }
  @media (max-width: 1570px) {
    .summeryField {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  @media (max-width: 1500px) {
    padding: 20px;
    h2 {
      font-size: 36px;
      span {
        font-size: 16px;
      }
    }
    h4 {
      padding-left: 20px;
      font-size: 16px;
    }
  }
  @media (max-width: 1366px) {
    .tripBoxCol {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 1200px) {
    h2 {
      font-size: 30px;
      gap: 10px;
      span {
        font-size: 14px;
      }
    }
    h4 {
      font-size: 14px;
    }
    .summeryField {
      padding-top: 16px !important;
      p {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 599px) {
    .tripCol:not(:last-child) {
      margin-bottom: 20px;
    }
    .summeryField {
      p {
        font-size: 16px;
        word-break: break-all;
        line-height: 1.3;
      }
    }
    .verticle_timeline_wrapper {
      margin: 0;
      padding: 0;
    }
  }
  .companytrip {
    align-items: center;
  }
  .fieldHasError {
    padding-bottom: 15px;
  }
`;
export const Buttons = styled.div`
  text-align: center;
  background: ${theme.colors.white};
  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
  @media (max-width: 450px) {
    padding: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 400px) {
    flex-direction: column;
    padding-top: 0;
    gap: 10px;
  }
  button {
    width: 208px;
    border-radius: 26.5px;
    background: ${theme.colors.black};
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: none;
    padding: 12px 22px;
    svg {
      margin-right: 5px;
      width: 22px;
      height: 22px;
      path {
        fill: ${theme.colors.white};
      }
    }
    &.green {
      background: ${theme.colors.green};
      margin-right: 16px;
      svg {
        margin-right: 12px;
      }
    }
    &.grey {
      background: ${theme.colors.grey3};
    }
    @media (max-width: 1200px) {
      padding: 8px 14px;
      width: 180px;
      font-size: 16px;
    }
    @media (max-width: 450px) {
      &.grey {
        margin-right: 0;
      }
    }
  }
`;
export const BlockTitle = styled.h3`
  font-size: 30px;
  font-weight: 700;
  line-height: 32.45px;
  letter-spacing: -0.02em;
  margin: 0px;
  text-align: center;
`;
export const SummaryNote = styled.div`
  font-size: 20px;
  line-height: 25px;
`;
export const ErrorMsg = styled.div`
  font-family: League Spartan;
  font-size: 15px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: -0.02em;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.red};
  @media (max-width: 400px) {
    flex-direction: column !important;
    gap: 10px;
  }
`;
export const CheckMarkRed = styled.div`
  width: 25px;
  height: 25px;
  font-weight: 700;
  background: ${theme.colors.red};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: ${theme.colors.white};
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 899px) {
    justify-content: center;
    margin-top: 15px;
  }
`;

export const SingleStep = styled.div`
  .MuiStepLabel-iconContainer {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.checkmarkColor};
    &.Mui-active {
      background-color: ${theme.colors.orange};
    }
    &.Mui-disabled {
      background-color: ${theme.colors.borderColor};
    }
    @media (max-width: 1200px) {
      width: 20px;
      height: 20px;
      &.Mui-completed {
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .MuiStepLabel-label {
    &.Mui-active {
      color: ${theme.colors.orange};
    }
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  .MuiStepper-root {
    overflow: visible;
    @media (max-width: 599px) {
      .MuiStep-root {
        min-width: 80px;
      }
    }
  }
  .MuiStepConnector-root {
    left: calc(-50% + 15px);
    right: calc(50% + 15px);
  }
  .MuiStepConnector-line {
    border-color: ${theme.colors.grey1};
  }
  .MuiStepLabel-label {
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: -0.02em;
    text-align: center;
    max-width: 95px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    &.Mui-completed {
      color: ${theme.colors.checkmarkColor};
    }
  }
`;

export const CurrentStepBg = styled.div`
  position: absolute;
  background: ${theme.colors.grey4};
  top: -25px;
  height: calc(100% + 35px);
  left: 0;
  right: 0;
  z-index: -1;
  border-radius: 10px;
`;

export const StepContainerRow = styled.div`
  width: 100%;
  padding-top: 36px;
  .stepRow {
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 599px) {
    padding-top: 20px;
    .stepCol {
      overflow-x: scroll;
      overflow-y: visible;
      padding-bottom: 15px;
      padding-top: 20px;
    }
  }
`;

export const SummeryRow = styled.div`
  margin-top: 50px;
  .summeryWrap {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  @media (max-width: 899px) {
    margin-top: 30px;
  }
`;

export const PriceWrap = styled.div`
  h2 {
    display: flex;
    align-items: flex-end;
    gap: 15px;
  }
  h4 {
    padding-left: 35px;
    padding-top: 5px;
    &.pl-0 {
      padding-left: 0;
      text-align: center;
    }
  }
  @media (max-width: 599px) {
    h2 {
      flex-direction: column;
      align-items: center;
      gap: 5px;
      font-size: 24px;
      span {
        line-height: 1;
      }
    }
    h4 {
      padding-left: 0;
      text-align: center;
    }
  }
`;

export const PriceNoteWrap = styled.div`
  margin-top: 60px;
  @media (max-width: 599px) {
    margin-top: 25px;
    p {
      font-size: 14px;
    }
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: 100%;
  .MuiInputBase-input {
    height: 0.85em;
    width: 100%;
    padding: 12px;
  }
  .MuiSelect-select {
    height: 0.85em;
    width: 100%;
    padding: 6.5px 12px;
  }
  @media (max-width: 1600px) {
    gap: 8px;
  }
  &.fieldHasError {
    flex-wrap: wrap;
  }
  .eventTypeText {
    @media (max-width: 1570px) {
      width: 60px;
    }
  }
  .eventTypeSelect {
    @media (max-width: 1570px) {
      width: calc(100% - 68px);
      max-width: 100%;
    }
  }
`;
export const OfferedText = styled.div`
  text-align: center;
  h3 {
    font-size: 23px;
    font-weight: bold;
  }
`;

export const FormAction = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const OfferFormCol = styled.div`
  width: calc(100% - 102px);
  position: relative;
  .MuiTextField-root {
    width: 100%;
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
  }
`;
