import React, { useEffect, useState } from 'react';
import { callGetAPI } from '../../services/auth';
import TripStopsSummary, { TripItinerary } from './index';
import CustomerInfo from '../CustomerInfo';

export const TripStopsSummaryWithData = ({ tripRequestCode }) => {
  const [tripData, setTripData] = useState();

  useEffect(() => {
    getTripRequestData({ tripRequestCode }).then((response) => {
      console.log(response);
      setTripData(response?.data?.data);
    });
  }, [tripRequestCode]);

  return (
    <>
      {tripData && tripData.client && (
        <CustomerInfo
          firstName={tripData.client.client_name}
          surname={tripData.client.client_surname}
          clientType={tripData.client.client_type}
          companyAddress={tripData.client.company_address}
          companyNumber={tripData.client.company_number}
          companyVatNumber={tripData.client.company_vat_number}
          email={tripData.client.requestor_email}
        />
      )}
      <TripStopsSummary tripData={tripData} />
      <TripItinerary tripData={tripData} />
    </>
  );
};

const getTripRequestData = async ({ tripRequestCode }) => {
  return await callGetAPI({
    route: `/trip-requests/${tripRequestCode}/details`,
  });
};

export default TripStopsSummaryWithData;
