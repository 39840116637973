import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Box, Paper, IconButton, Typography, Container } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { authGet, authPost } from '../../../services/auth';

const ChatInterface = () => {
  const { tripRequestCode } = useParams();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const editorRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    authGet({
      route: `v1/conversations/trip-requests/${tripRequestCode}/bus-partner`,
    }).then((result) => {
      setIsLoading(false);
      setMessages(
        result.data.map((message) => ({
          id: message.id,
          type: message.type,
          text: message.message,
          timestamp: message.creationDate,
          isSent: message.direction === 0,
        })),
      );
    });
    console.log(messagesEndRef.current);
  }, [tripRequestCode]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    setIsLoading(true);

    const currentTime = new Date();

    await authPost({
      route: `v1/conversations/trip-requests/${tripRequestCode}/bus-partner-send`,
      body: {
        message: newMessage,
        clientTimestamp: new Date().toISOString(),
      },
    }).then((_) => {
      setIsLoading(false);
      setNewMessage('');
      editorRef.current?.setContent('');
      setMessages((prev) => [
        ...prev,
        {
          id: prev[prev.length - 1].id + 1,
          text: newMessage,
          timestamp: currentTime,
          isSent: true,
        },
      ]);
    });
  };

  return (
    <Container sx={{ py: 2 }}>
      <Paper
        border={1}
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            bgcolor: 'white',
          }}>
          {messages.map((message, index) => (
            <Box
              ref={index === messages.length - 1 ? messagesEndRef : null}
              key={message.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: message.isSent ? 'flex-end' : 'flex-start',
                maxWidth: '70%',
                alignSelf: message.isSent ? 'flex-end' : 'flex-start',
              }}>
              <ChatEntry message={message} />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            p: 2,
            bgcolor: 'background.paper',
            borderTop: 1,
            borderColor: 'divider',
          }}>
          <Box
            sx={{
              display: 'flex',
              minHeight: '150px',
              gap: 1,
              flexDirection: 'row',
            }}>
            <Box
              sx={{
                flexGrow: 1,
              }}>
              <Editor
                onInit={(_evt, editor) => (editorRef.current = editor)}
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                licenseKey={'gpl'}
                onEditorChange={(content, editor) => {
                  setNewMessage(content);
                }}
                init={{
                  height: 140,
                  language_url: '/tinymce/lang/it.js',
                  language: 'it',
                  menubar: false,
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'preview',
                    'wordcount',
                  ],
                  toolbar:
                    'undo redo | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            </Box>
            <SendButton
              isLoading={isLoading}
              messageToSend={newMessage}
              handleSendMessage={handleSendMessage}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

const StandardMessage = ({ message }) => {
  return (
    <>
      <Paper
        elevation={1}
        sx={{
          p: 1,
          bgcolor: message.isSent ? '#5C5BE636' : 'white',
          color: 'black',
          borderRadius: 1,
          borderBottomRightRadius: message.isSent ? 0 : 10,
          borderBottomLeftRadius: message.isSent ? 10 : 0,
        }}>
        <div dangerouslySetInnerHTML={{ __html: message.text }} />
      </Paper>
      <Typography
        variant="caption"
        sx={{
          mt: 0.5,
          color: 'text.secondary',
        }}>
        {new Date(message.timestamp).toLocaleString('it-IT')}
      </Typography>
    </>
  );
};

const CustomerNote = ({ message }) => {
  return (
    <>
      <Typography
        variant="caption"
        sx={{
          mt: 0.5,
          color: 'text.primary',
          fontWeight: 'bold',
        }}>
        Nota del cliente
      </Typography>
      <Paper
        sx={{
          p: 1,
          bgcolor: '#f7f7f7',
          color: 'black',
          borderRadius: 1,
        }}>
        <div dangerouslySetInnerHTML={{ __html: message.text }} />
      </Paper>
      <Typography
        variant="caption"
        sx={{
          mt: 0.5,
          color: 'text.secondary',
        }}>
        {new Date(message.timestamp).toLocaleString('it-IT')}
      </Typography>
    </>
  );
};

const ChatEntry = ({ message }) => {
  switch (message.type) {
    case 1:
      return <CustomerNote message={message} />;
    default:
      return <StandardMessage message={message} />;
  }
};

const SendButton = ({ handleSendMessage, messageToSend, isLoading }) => {
  return (
    <IconButton
      color="primary"
      onClick={handleSendMessage}
      disabled={
        isLoading || messageToSend == null || messageToSend.trim() === ''
      }
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        '&:hover': {
          bgcolor: 'primary.dark',
        },
        '&.Mui-disabled': {
          bgcolor: 'action.disabledBackground',
          color: 'action.disabled',
        },
        width: 48, // Set a fixed width for the button
        height: 48, // Set a fixed height for the button
        alignSelf: 'flex-start',
      }}>
      {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
    </IconButton>
  );
};

export default ChatInterface;
