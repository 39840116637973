import styled from '@emotion/styled';
import theme from '../../themes/base.json';

export const TripBox = styled.div`
  border-radius: 21px;
  border: 1px solid #d9d9d9;
  padding: 30px;
  margin: 15px;
  .verticle_timeline_wrapper {
    .MuiTimelineItem-root {
      .MuiTimelineContent-root {
        position: relative;
        top: -25px;
        @media (max-width: 599px) {
          top: -45px;
        }
      }
      &:first-of-type {
        .MuiTimelineContent-root {
          top: -6px;
          padding-bottom: 26px;
        }
      }
      &:last-child {
        top: -10px;
        .MuiTimelineContent-root {
          top: -6px;
          padding-bottom: 26px;
        }
        @media (max-width: 599px) {
          top: -20px;
          .MuiTimelineContent-root {
            top: -10px;
          }
        }
      }
      @media (max-width: 599px) {
        min-height: 140px;
      }
    }
  }
  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.02em;
    text-align: left;
    span {
      font-size: 20px;
      line-height: 32.45px;
      font-weight: 400;
      color: ${theme.colors.grey3};
    }
  }
  h4 {
    font-size: 20px;
    font-weight: 700;
  }
  p {
    font-size: 20px;
    span {
      font-weight: 700;
    }
    &.Mui-error {
      font-size: 14px;
      line-height: 1;
    }
    @media (max-width: 1500px) {
      font-size: 18px;
    }
  }
  .MuiTimelineItem-root {
    .MuiTimelineDot-root {
      margin: 0;
    }
    &:last-child {
      .MuiTimelineConnector-root {
        display: none;
      }
    }
    h3 {
      color: ${theme.colors.black};
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: -0.02em;
      @media (max-width: 1500px) {
        font-size: 18px;
        line-height: 23px;
      }
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 1.3;
      }
    }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: ${theme.colors.timelineDesc};
      @media (max-width: 599px) {
        line-height: 20px;
      }
    }
  }
  .MuiTimelineSeparator-root {
    .MuiTimelineDot-root {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-weight: 700;
      letter-spacing: -0.02em;
      box-sizing: border-box;
      @media (max-width: 599px) {
        width: 25px;
        height: 25px;
        font-size: 16px;
      }
    }
    .MuiTimelineConnector-root {
      border-color: ${theme.colors.grey1};
    }
  }
  .tripBoxLabel {
    width: 90px;
  }
  @media (max-width: 1570px) {
    .summeryField {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  @media (max-width: 1500px) {
    padding: 20px;
    h2 {
      font-size: 36px;
      span {
        font-size: 16px;
      }
    }
    h4 {
      padding-left: 20px;
      font-size: 16px;
    }
  }
  @media (max-width: 1366px) {
    .tripBoxCol {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 1200px) {
    h2 {
      font-size: 30px;
      gap: 10px;
      span {
        font-size: 14px;
      }
    }
    h4 {
      font-size: 14px;
    }
    .summeryField {
      padding-top: 16px !important;
      p {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 599px) {
    .tripCol:not(:last-child) {
      margin-bottom: 20px;
    }
    .summeryField {
      p {
        font-size: 16px;
        word-break: break-all;
        line-height: 1.3;
      }
    }
    .verticle_timeline_wrapper {
      margin: 0;
      padding: 0;
    }
  }
  .companytrip {
    align-items: center;
  }
  .fieldHasError {
    padding-bottom: 15px;
  }
`;
